
import { defineComponent, onMounted, ref, Ref } from "vue";
import useEmitter from "@/composables/Emmiter";
import FilterBase from "@/layout/header/partials/filters/FilterBase.vue";
import { ElConfigProvider } from "element-plus";
import ptBr from "element-plus/lib/locale/lang/pt-br";
import { date } from "yup";
import Api from "@/services/Api";

interface Input {
  cargo: string,
  data: string
}

export default defineComponent({
  name: "FilterUsers",
  components: {
    FilterBase,
    ElConfigProvider,
  },

  setup(props, { emit }) {
    const emitter = useEmitter();

    const activeModal = ref(false);
    const closeModal = ref(false);

    const input: Ref<Input> = ref({
      cargo: "",
      data: ""
    });

    const cargos: Ref<any> = ref([]);


    async function enviarEmit() {

      await emitter.emit("FilterUser", { input: input.value, });

      closeModal.value = !closeModal.value;

    }


    onMounted(async()=>{ cargos.value = (await Api.get('getCargosUsuario') as any).data.cargos || [] });

    return {
      input,
      cargos,
      enviarEmit,
      closeModal,
      ElConfigProvider,
      ptBr,
    };
  },
});
